import { AuthConfig } from "angular-oauth2-oidc";

export const environment:any = {
  production: true,
  API_URL: 'https://content-api-dev.eztek.net',
  name: 'dev',
  RESOURCE_URL: "https://id-api-test.trueconnect.vn",
  NOTIFICATION_URL: "https://hub-server.trueconnect.vn",
  BASE_URL: "https://me.trueconnect.vn/profile",
  BASE_URL_UPLOAD: "https://cdn-test.eztek.net/gateway/Media/Upload",
  WEB_URL: "https://me.trueconnect.vn/profile/",
  SOCKET_URL: "https://me.trueconnect.vn/profile",
  API_URL_2: "https://id-api-test.trueconnect.vn",
  API_URL3: 'https://api-dev.trueconnect.vn',
  BASE_URL_1: "https://api-agency-dev.trueconnect.vn",
  URL_AGENCY: "https://id-test.trueconnect.vn"
};

export const authCodeFlowConfig: Partial<AuthConfig> = {
  // Url of the Identity Provider
  issuer: 'https://id-test.trueconnect.vn',
  clientId: 'localhost_identity_short',
  responseType: 'code',
  oidc: false,
  dummyClientSecret: 'no_important',
  redirectUri: 'https://pos-demo.giangvt.one',
  // redirectUri: 'http://localhost:4200',
  scope: 'openid profile email offline_access roles',
  clearHashAfterLogin: false,
};
